import registerLoaders from './registerLoaders.js';

let cornerstone;

const external = {
  set cornerstone(cs) {
    cornerstone = cs;

    registerLoaders(cornerstone);
  },
  get cornerstone() {
    return cornerstone;
  },
};

export { external };
