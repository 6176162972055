function convertImageIdToBlobUrl(imageId) {
  const parts = imageId.split('/');
  const seriesUid = parts[parts.length - 5];
  const instanceId = parts[parts.length - 3];
  const frame = parts[parts.length - 1];

  const azureData = window.azureData;
  const url = `https://${azureData.account}.blob.core.windows.net/${azureData.container}/${azureData.object}/ohif/${seriesUid}/${instanceId}_${frame}.jpg`;
  return url;
}

export default convertImageIdToBlobUrl;
