import { external } from './externalModules.js';
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';

const canvas = document.createElement('canvas');
let lastImageIdDrawn;

/**
 * creates a cornerstone Image object for the specified Image and imageId
 *
 * @param image - An Image
 * @param imageId - the imageId for this image
 * @returns Cornerstone Image Object
 */
export default function(image, imageId) {
  // extract the attributes we need
  const rows = image.naturalHeight;
  const columns = image.naturalWidth;

  function getPixelData() {
    const imageData = getImageData();

    const task = cornerstoneWADOImageLoader.webWorkerManager.addTask(
      'rgbGrayscaleConvert',
      {
        data: imageData.data.buffer,
        width: imageData.width,
        height: imageData.height,
      },
      -8
    );
    const promise = task.promise;
    return promise;
  }

  function getImageData() {
    let context;

    if (lastImageIdDrawn === imageId) {
      context = canvas.getContext('2d');
    } else {
      canvas.height = image.naturalHeight;
      canvas.width = image.naturalWidth;
      context = canvas.getContext('2d');
      context.drawImage(image, 0, 0);
      lastImageIdDrawn = imageId;
    }

    return context.getImageData(0, 0, image.naturalWidth, image.naturalHeight);
  }

  function getCanvas() {
    if (lastImageIdDrawn === imageId) {
      return canvas;
    }

    canvas.height = image.naturalHeight;
    canvas.width = image.naturalWidth;
    const context = canvas.getContext('2d');

    context.drawImage(image, 0, 0);
    lastImageIdDrawn = imageId;

    return canvas;
  }

  // Extract the various attributes we need
  return getPixelData().then(result => {
    const pixels = new Uint8ClampedArray(result.grayscaleImg);
    const getPixels = () => pixels;
    return {
      imageId,
      minPixelValue: 0,
      maxPixelValue: 255,
      slope: 1,
      intercept: 0,
      windowCenter: 128,
      windowWidth: 255,
      // render: external.cornerstone.renderWebImage,
      getPixelData: getPixels,
      // getCanvas,
      // getImage: () => image,
      imageFrame: {
        samplesPerPixel: 1,
        // photometricInterpretation: 'MONOCHROME2',
        rows,
        columns,
        bitsAllocated: 8,
        pixelData: pixels,
        bitsStored: 8,
        pixelRepresentation: 1,
        smallestPixelValue: 0,
        largestPixelValue: 255,
        pixelDataLength: rows * columns,
      },
      rows,
      columns,
      height: rows,
      width: columns,
      color: false,
      rgba: false,
      // columnPixelSpacing: undefined,
      // rowPixelSpacing: undefined,
      invert: false,
      sizeInBytes: rows * columns * 1,
    };
  });
}
